.fc h2.fc-toolbar-title {
  font-size: 22px;
  margin: 0;
}

/* month events */
a.fc-daygrid-event {
  color: #509156;
  white-space: normal;
  align-items: flex-start;
}
@media (max-width: 959.95px) {
  a.fc-daygrid-dot-event {
    flex-wrap: wrap;
  }
  a.fc-daygrid-dot-event .fc-daygrid-event-dot {
    margin: 4px 4px 4px 0;
  }
  a.fc-daygrid-dot-event .fc-event-title {
    flex-basis: 100%;
  }
}
a.fc-daygrid-event.ride {
  color: #2E86AB;
}
a.fc-daygrid-event.event,
a.fc-daygrid-event.onlineEvent {
  color: #BB8FA9;
}

/* month event dots */
a.fc-daygrid-event .fc-daygrid-event-dot {
  margin-top: 4px;
}
a.fc-daygrid-event.ride .fc-daygrid-event-dot {
  border-color: #2E86AB;
}
a.fc-daygrid-event.event .fc-daygrid-event-dot,
a.fc-daygrid-event.onlineEvent .fc-daygrid-event-dot {
  border-color: #BB8FA9;
}

/* month block events */
a.fc-daygrid-event.fc-daygrid-block-event.ride {
  background-color: #2E86AB;
}
a.fc-daygrid-event.fc-daygrid-block-event.event,
a.fc-daygrid-event.fc-daygrid-block-event.onlineEvent {
  background-color: #BB8FA9;
}

/* week events */
a.fc-timegrid-event.ride {
  background-color: #2E86AB;
  border-color: #2E86AB;
}
a.fc-timegrid-event.event,
a.fc-timegrid-event.onlineEvent {
  background-color: #BB8FA9;
  border-color: #BB8FA9;
}

/*.fc-right .fc-prev-button,
.fc-right .fc-next-button {
  background-color: #509156;
  background-image: none;
}
*/
